import React , {useEffect} from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ScrollHandler from "../ScrollHandler";
import nodejsImage from "../../Assets/Images/nodejsBlogImage.png";
import nodeHumanHolding from "../../Assets/Images/node-js-human-image.jpg";

function Nodejs() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
      <Navbar />
      <section id="Blog">
        <section className="Blog-Cards-Container">
          <div className="Blog-Cards">
            <section className="react-js">
              <header className="header">
                <h1>Node js</h1>
                <img src={nodejsImage} alt="error loading image" />
              </header>
              <span className="Time">
                <p className="Month">March</p>
                <p className="Year">2023</p>
              </span>

              <section className="Full-Content-Container">
                <div className="heading">
                  <h1>
                    What is <h2 className="High-Lights">Node Js</h2>
                  </h1>
                </div>
                <div className="Defination">
                  <p>
                    Node.js is a JavaScript runtime built on Chrome’s V8
                    JavaScript engine. It uses an event-driven, non-blocking I/O
                    model, that makes it lightweight and efficient. Node.js’
                    package ecosystem, npm, is the largest ecosystem of open
                    source libraries in the world. The Node.js platform is used
                    for building fast, scalable network applications. It’s ideal
                    for data-intensive real-time applications that run across
                    distributed devices. The Node.js Foundation is the steward
                    of the Node.js open source project and works to advance the
                    technology, grow a diverse and vibrant community, and define
                    future direction. Node.js was created by Ryan Dahl in 2009.
                    It was originally written in C++ but later rewritten in
                    JavaScript in an event loop model — similar to the browser —
                    with a single thread of execution and support for multiple
                    input/output (I/O) operations at once.
                  </p>
                </div>

                <div className="nodejs-feature">
                  <header>
                    <h1>Features of NodeJS</h1>
                  </header>

                  <div className="node-js-points">
                    <div className="points">
                      <p>
                        <span className="High-Lights">Execution :</span>As Node
                        uses google’s v8 JavaScript engine to execute code,it
                        also uses single thread at a time to avoid blocking an
                        event loop for unnecessary tasks.
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">Performance :</span>
                        Node.js uses non-blocking I/O and an event-driven
                        architecture that makes it lightweight and efficient
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">Easy Installation :</span>
                        The Node.js package manager, npm, simplifies the
                        installation of packages from the Node Package
                        Repository (NPM), making it easy to share code and reuse
                        modules
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">
                          Ease of Development :
                        </span>
                        The asynchronous nature of Node makes it easy to write
                        code that handles multiple requests at once, making
                        development much faster than with most other languages
                      </p>
                    </div>

                    <div className="node-img-content">
                      <a href="https://nodejs.org/en/docs" target="_blank">
                        {" "}
                        <img
                          src={nodeHumanHolding}
                          alt="error loading image."
                        />
                      </a>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">
                          Serverless Architecture :
                        </span>
                        The trend of serverless architecture has gained
                        popularity and Node.js is a good fit for it. Node.js
                        helps developers to build serverless applications that
                        are scalable, cost-effective, and easy to manage.
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">Microservices :</span>
                        Node.js is well-suited for building microservices
                        because of its lightweight nature and ability to handle
                        many requests at once.
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">
                          Real Time Applications :
                        </span>
                        Node.js is a popular choice for building real-time
                        applications that require low-latency communication
                        between clients and servers.
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">
                          Internet of Things (IoT) :
                        </span>
                        Node.js is becoming more popular for building IoT
                        applications, due to its ability to handle large amounts
                        of data in real-time.
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">Machine Learning :</span>
                        Node.js can be used for real-time machine learning
                        applications that require the processing of large
                        amounts of data in real-time
                      </p>
                    </div>

                    <div className="points">
                      <p>
                        <span className="High-Lights">DevOps :</span>
                        Node.js is a popular choice for building DevOps tools
                        that automate tasks and improve efficiency in the
                        development process.
                      </p>
                    </div>
                  </div>

                  <section className="nodejs-conclusion">
                    <p>
                      Therefore Node.js has become a popular choice for
                      developing web applications, APIs, real-time communication
                      systems, and even desktop applications. It allows
                      developers to write server-side code in JavaScript, which
                      simplifies the development process and makes it easier to
                      share code between the server and the client. Node.js is
                      also known for its scalability, allowing applications to
                      handle a large number of concurrent connections without
                      sacrificing performance. Its lightweight and efficient
                      architecture, along with its vast ecosystem of modules and
                      libraries, make it a compelling choice for many
                      developers.
                    </p>
                  </section>
                </div>
              </section>
            </section>
          </div>
        </section>
      </section>

      <Footer />
      <ScrollHandler />
    </>
  );
}

export default Nodejs;
