import React, { useState , useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ScrollHandler from "../ScrollHandler";
import WorKlifeBalance from "../../Assets/Images/work-life-balance.svg";
import Comms from "../../Assets/Images/comms.svg";
import Celebration from "../../Assets/Images/celebration.svg";
import TeamWork from "../../Assets/Images/team-work.svg";
import dummy from "../../Assets/Images/dummy.png";
import careerGroup from "../../Assets/Images/carrerGroup.svg";
import { Box, Modal } from "@mui/material";
import closeIcon from "../../Assets/Images/close-icon.svg";
import "./careers.scss";
function Careers() {
  const [curCareer, setCareer] = useState(1);
  const style = {};
  const handleModalClose = () => {
    setCareer(null);
  };
  const keyCards = [
    {
      title: "Work-life Balance",
      icon: WorKlifeBalance,
      desc: "Offering flexible work arrangements, promoting work-life balance, and prioritizing employee well-being can help to improve productivity and morale.",
    },
    {
      title: "Open communication",
      icon: Comms,
      desc: "Encouraging open communication, feedback, and transparency can help to build trust, improve performance, and foster a positive work environment.",
    },
    {
      title: "Celebrating achievements",
      icon: Celebration,
      desc: "Recognizing and celebrating achievements can help to build morale, promote teamwork, and foster a positive culture.",
    },
    {
      title: "Collaboration and teamwork",
      icon: TeamWork,
      desc: "Offering flexible work arrangements, promoting work-life balance, and prioritizing employee well-being can help to improve productivity and morale.",
    },
  ];
  const careerCards = [
    {
      title: "Senior Full Stack Developer",
      id: "1",
      postedDate: "Posted on 10th March, 2023",
      shortDesc:
        "We are seeking a highly skilled and experienced Senior Fullstack Developer to join our team. The ideal candidate should have a strong background in both front-end and back-end development, with a passion for creating..",
      desc: "We are seeking a highly skilled and experienced Senior Fullstack Developer to join our team. The ideal candidate should have a strong background in both front-end and back-end development, with a passion for creating exceptional user experiences. As a Senior Fullstack Developer, you will be responsible for designing, developing, testing, and maintaining web applications that meet the needs of our clients.",
      responsibilities: [
        "Design and develop web applications using modern front-end frameworks, such as Angular or React, and back-end technologies, such as Node.js or Django.",
        "Collaborate with product managers, designers, and other developers to create compelling user experiences and features.",
        "Write clean, maintainable, and testable code that adheres to best practices and coding standards.",
        "Conduct code reviews and provide constructive feedback to other developers.",
        "Continuously research new technologies and frameworks to stay up-to-date with industry trends and best practices.",
        "Mentor and train junior developers to help them grow and develop their skills.",
      ],
      requirements: [
        "Bachelor's or Master's degree in Computer Science or a related field.",
        "At least 5 years of experience in fullstack web development.",
        "Expertise in modern front-end frameworks, such as Angular or React.",
        "Strong skills in back-end development using Node.js, Django, or similar technologies.",
        "Experience with databases such as MySQL, MongoDB, or PostgreSQL.",
        "Familiarity with cloud-based platforms such as AWS, GCP or Azure.",
        "Excellent problem-solving skills and ability to work in a fast-paced environment.",
        "Strong communication skills and ability to work collaboratively with a team.",
        "Experience with agile development methodologies.",
        "Passion for learning and continuous improvement.",
      ],
      applicationLink: "",
    },
  ];

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <>
      <Navbar curPage="careers" />
      <div className="main">
        <div className="container head">
          <h1>
            Let’s grow <span className="highlight"> together </span> here at{" "}
            <span className="highlight black">CapNxt</span>
          </h1>
          <a href="#careers">
            <button>Open Positions</button>
          </a>
        </div>
        <div className="culture">
          <div className="container">
            <h1>Our Culture</h1>
            <div className="row">
              <div className="col key-points">
                <div className="row">
                  {keyCards.map((card) => {
                    return (
                      <div className="col key-point-card">
                        <img src={card.icon} alt="" />
                        <h3 className="card-title">{card.title}</h3>
                        <p className="card-content">{card.desc}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col image">
                <img src={careerGroup} alt="error loadingi image" />
              </div>
            </div>
          </div>
        </div>
        <div className="careers" id="careers">
          <div className="container">
            <h1>Work with us</h1>

            {careerCards.map((card) => {
              return (
                <div className="career-card">
                  <div className="head">
                    <h3>{card.title}</h3>
                    <p className="date">{card.postedDate}</p>
                  </div>
                  <p className="desc">
                    {card.shortDesc}
                    <button
                      className="read-more"
                      onClick={() => {
                        setCareer(card.id);
                      }}
                    >
                      read more...
                    </button>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {careerCards.map((card) => {
        return (
          <Modal
            open={card.id === curCareer}
            onClose={handleModalClose}
            style={{ overflow: "scroll" }}
          >
            <Box style={style} className="job-modal">
              <div className="head">
                <h1 className="title">{card.title}</h1>
                {/* <button>Apply Now</button> */}
              </div>
              <div className="description">
                <h3>Job Description</h3>
                <p>{card.desc}</p>
              </div>
              <div className="responsibilities">
                <h3>Responsibilities</h3>
                <p>
                  <ul>
                    {card.responsibilities.map((el) => {
                      return <li>{el}</li>;
                    })}
                  </ul>
                </p>
              </div>
              <div className="requirements">
                <h3>Requirements</h3>
                <p>
                  <ul>
                    {card.requirements.map((el) => {
                      return <li>{el}</li>;
                    })}
                  </ul>
                </p>

                <p style={{color:"black",fontWeight: 500}}>Email : info@capnxt.com | ph.no : 7981516766</p>
              </div>
              <div
                className="close-btn"
                onClick={() => {
                  setCareer(null);
                }}
              >
                <img src={closeIcon} alt="" />
                {/* asdf */}
              </div>
            </Box>
          </Modal>
        );
      })}

      <Footer />
      <ScrollHandler />
    </>
  );
}

export default Careers;
