import React from "react";
import Oskii from "../Assets/Images/Oskii.svg";
import findpath from "../Assets/Images/find-path.svg";
import "../StyleComponents/PageNotFound.css";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

function PageNotFound() {
  return (
    <>
      <Navbar />
      <section className="Error">
        <div className="oscar-img">
          <img src={Oskii} alt="Error Loading Image.." />
        </div>
        <div className="error-content">
          <p>
            Hello! Im <span className="Oscar">Oscar</span>{" "}
            <span className="Since"> ... since 2016</span>
          </p>
          <div className="page-not-found">
            <h1>404</h1>
            <p>Page Not Found</p>
          </div>
        </div>
      </section>
      <section className="find-path">
        <p>you can find me here!!</p>
        <img src={findpath} alt="Error loading  in image." />
      </section>
      <Footer />
    </>
  );
}

export default PageNotFound;
