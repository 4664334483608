import React, { useState } from "react";
import "../StyleComponents/Demo.css";

function Demo() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.value);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      {/* <div className="dropdown-container">
        <div className="selected-option" onClick={toggleDropdown}>
          {selectedOption ? selectedOption : "Select an option"}
        </div>
        {dropdownOpen && (
          <select className="dropdown" onChange={handleOptionSelect}>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
        )}
      </div> */}
    </>
  );
}

export default Demo;
