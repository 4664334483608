import React, { useEffect } from "react";
import Navbar from "../Navbar";
import "../../StyleComponents/Blog.css";
import aws from "../../Assets/Images/awsImage.svg";
import cloudImage from "../../Assets/Images/cloudImage.png";
import Footer from "../Footer";
import ScrollHandler from "../ScrollHandler";

function Computing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <section id="Blog">
        <section className="Blog-Cards-Container">
          <div className="Blog-Cards">
            <header className="Tag-line">
              <h1> Cloud Computing </h1>
              <img src={cloudImage} alt="" />
            </header>

            <span className="Time">
              <p className="Month">Febraury</p>
              <p className="Year">2023</p>
            </span>

            <section className="Full-Content-Container">
              <div className="heading">
                <h1>
                  What is <h2 className="High-Lights">Cloud Computing</h2>
                </h1>
              </div>
              <div className="Defination">
                <p>
                  <span className="High-Lights"> Cloud computing</span> is the
                  delivery of on-demand computing resources over the internet.
                  It allows users to access a range of computing services,
                  including servers, storage, databases, software, and
                  applications, without the need for local infrastructure or
                  technical expertise. Cloud computing provides a flexible and
                  cost-effective way for individuals and businesses to access
                  and use computing resources. It enables users to quickly and
                  easily provision resources, scale up or down as needed, and
                  pay only for what they use.
                </p>
              </div>
              <div className="Sub-heading">
                <p>Primary Service categories in Cloud-computing</p>
              </div>
              <div className="cloud-points">
                <p>
                  1.
                  <span className="High-Lights">
                    {" "}
                    Infrastructure-as-a-Service (IaaS):
                  </span>{" "}
                  Provides virtualized computing resources such as servers,
                  storage, and networking.
                </p>

                <p>
                  2.{" "}
                  <span className="High-Lights">
                    Platform-as-a-Service (PaaS):{" "}
                  </span>{" "}
                  Provides a platform for building, testing, and deploying
                  applications.
                </p>
                <p>
                  3.{" "}
                  <span className="High-Lights">
                    Software-as-a-Service (SaaS):
                  </span>{" "}
                  Provides access to software applications hosted in the cloud.
                </p>
              </div>
              <p className="conclusion">
                Cloud computing is widely used across many industries and has
                transformed the way businesses and individuals access and use
                computing resources.
              </p>
              <div className="Why-use">
                <header>
                  <h1>Why Use Cloud Computing ?</h1>
                  <img src={aws} alt="error loading image" />
                </header>
              </div>

              <div className="Defination">
                <p>
                  We use <span className="High-Lights"> Cloud Computing </span>
                  to improve the operations and provide better services to our
                  customers. Here are some common use cases for cloud computing.
                </p>
              </div>

              <div className="cloud-points ">
                <p>
                  1.
                  <span className="High-Lights">Scalability:</span> Cloud
                  computing allows service-based companies to scale their
                  operations up or down based on demand. This means that they
                  can quickly add or remove computing resources as needed to
                  meet the needs of their customers.
                </p>

                <p>
                  2. <span className="High-Lights">Collaboration: </span> Cloud
                  computing provides a platform for collaboration between
                  employees and teams in service-based companies. Cloud-based
                  applications and tools allow employees to work together on
                  projects and share information in real-time, no matter where
                  they are located.
                </p>
                <p>
                  3.<span className="High-Lights">Cost savings:</span> Cloud
                  computing can help service-based companies reduce their costs
                  by eliminating the need for expensive on-premises hardware and
                  infrastructure. They can instead use cloud-based services to
                  access the resources they need, paying only for what they use.
                </p>

                <p>
                  4. <span className="High-Lights">Security:</span> Cloud
                  computing providers typically offer robust security measures
                  to protect their customers' data and applications. This can
                  help service-based companies maintain the privacy and
                  confidentiality of their clients' information.
                </p>

                <p>
                  5. <span className="High-Lights">Innovation:</span>Cloud
                  computing enables service-based companies to experiment with
                  new services and business models without the need for
                  significant upfront investment in hardware and infrastructure.
                  This can help them stay competitive and adapt to changing
                  market conditions.
                </p>
              </div>

              <div className="Current-Target">
                <header>
                  <h1>Cloud Computing Currently</h1>
                </header>
                <p className="content">
                  The ongoing mass adoption of cloud computing has been a key
                  driver of many of the most transformative tech trends,
                  including artificial intelligence (AI), the internet of things
                  (IoT), and remote and hybrid working. Going forward, we can
                  expect to see it becoming an enabler of even more
                  technologies, including{" "}
                  <span className="High-Lights"> virtual</span>and{" "}
                  <span className="High-Lights">
                    augmented reality (VR/AR),
                  </span>{" "}
                  the <span className="High-Lights">metaverse</span>,{" "}
                  <span className="High-Lights">cloud gaming</span>, and even{" "}
                  <span className="High-Lights">quantum computing. br</span>{" "}
                  Cloud computing makes this possible by removing the need to
                  invest in buying and owning the expensive infrastructure
                  required for these intensive computing applications. Instead,
                  cloud service providers make it available "as-a-service,"
                  running on their own servers and data centres. It also means
                  companies can, to some extent, avoid the hassle of hiring or
                  training a highly specialized workforce if they want to take
                  advantage of these breakthrough technologies.
                </p>

                <div className="cloud-2023">
                  <span className="High-Lights">
                    In 2023 we can expect to see companies continuing to
                    leverage cloud services in order to access new and
                    innovative technologies as well as drive efficiencies in
                    their own operations and processes.
                  </span>
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>

      <Footer/>
      <ScrollHandler/>
    </>
  );
}

export default Computing;
