import React, { useEffect } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ScrollHandler from "./ScrollHandler";
import "../StyleComponents/HomeStyle.css";
import "../StyleComponents/AboutStyle.css";
import clientOne from "../Assets/Images/clientOne.svg";
import clientTwo from "../Assets/Images/clientTwo.svg";
import clientThree from "../Assets/Images/ClientThree.svg";
import clientFour from "../Assets/Images/clientFour.svg";
import vaikhari from "../Assets/Images/vaikhari.svg";
import JishnuSir from "../Assets/Images/jishnuSir.svg";
import aboutUsBackground from "../Assets/Images/aboutusBg.svg";
// import openingQuotes from "../Assets/Images/openingQuotes.png";
import openingQuotes from "../Assets/Images/openingQuotes.svg";
import ClosingQuotes from "../Assets/Images/ClosingQuotes.svg";
import { Link } from "react-router-dom";


function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar curPage="about" />

      <section id="about-us">
        <header>
          <h1>Our Vision</h1>
          <p>
            Our vision at CapNxt is to empower businesses with advanced web apps
            and data analysis tools to drive growth, efficiency, and innovation.
            We aim to be a leading provider of cutting-edge technology solutions
            that deliver tangible value to our clients while upholding the
            highest standards of data privacy and ethical practices.
          </p>
        </header>

        <div className="about-director">
          <div className="image">
            <Link to="https://www.linkedin.com/in/jishnudantu/" target="_blank">
              {" "}
              <img src={JishnuSir} alt="Error loading image" />
            </Link>
          </div>

          <div className="about-content">
            <h2>Jishnu Dantu</h2>
            <p className="Position">CEO & Founder</p>
            <p>
              As CEO of CapNxt Global, Jishnu Dantu has fostered a culture of
              innovation and collaboration that has allowed the company to grow
              rapidly. Some of the key achievements under Jishnu Dantu's
              leadership include Director of Operations at Piersoft Technologies
              and a Masters in Data Science specializing in the fields of Signal
              Processing and Applied Statistics. He has brought a wealth of
              knowledge and expertise to the startup ecosystem.
            </p>

            <p className="desc">
             <span><img className="Opening-quotes" src={ClosingQuotes} alt="error loading images" /></span>We've made significant progress in the past year, and we're
              dedicated to providing top-notch services while upholding the
              highest ethical standards. I want to express my gratitude to our
              incredible team for all of their efforts and commitment to
              offering our clients cutting-edge solutions. Let's keep working
              together to create a successful future for our company<span><img className="Closing-quotes" src={openingQuotes} alt="error loading images" /></span> 
            </p>
          </div>
        </div>
 
        <div className="our-commitment">
          <h1>Our Commitment</h1>
          <p>
            We pledge to deliver high-quality web products and solutions that
            utilize data analytics to optimize performance. We commit in
            utilizing industry-standard web-development technologies to create
            visually appealing and user-friendly web products and provide
            ongoing support and maintenance services to ensure the continued
            functionality and performance of our products.
          </p>
        </div>

        <div className="HomeClients">
          <header>
            <h1>Our Clients</h1>
          </header>
          <div className="imageContainer">
            <div className="imageBox">
              <a href="https://energon.in" target="_blank">
                {" "}
                <img src={clientOne} alt="clientOne" />
              </a>
            </div>
            <div className="imageBox">
              <a href="https://vaikharidigital.com/" target="_blank">
                <img src={vaikhari} alt="clientOne" />
              </a>
            </div>
            <div className="imageBox" style={{ marginTop: "-1vw" }}>
              <a href="https://arkitonassociates.com" target="_blank">
                <img src={clientThree} alt="clientOne" />
              </a>
            </div>
            <div className="imageBox" style={{ marginTop: "-1vw" }}>
              <a href="https://prakrutischool.in" target="_blank">
                {" "}
                <img src={clientFour} alt="clientOne" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollHandler />
    </>
  );
}

export default AboutUs;
