import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../StyleComponents/HomeStyle.css";
import capnxtHeading from "../Assets/Images/capnxtLogoHeading.png";
import Logo from "../Assets/Images/logo-poppins.svg";
import aboutImg from "../Assets/Images/aboutMbl.svg";
import contactImg from "../Assets/Images/contactMbl.svg";
import HomeImg from "../Assets/Images/HomeMbl.svg";
import OurWorkImg from "../Assets/Images/ourWorkMbl.svg";
import ServiceImg from "../Assets/Images/ServiceMbl.svg";
import linkedIn from "../Assets/Images/linkedIn.svg";
import instagram from "../Assets/Images/instagram.svg";
import faceBook from "../Assets/Images/faceBook.svg";
import webIcon from "../Assets/Images/websiteLink.svg";
import dataBase from "../Assets/Images/dataBase.svg";
import CareerImage from "../Assets/Images/Carerrs.svg";
import downArrow from "../Assets/Images/down-arrow.png";
import upArrow from "../Assets/Images/up-arrow.png";

function Navbar(props) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");

  const [curPage, setCurPage] = useState("home");
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    navigate(`/${e.target.value}`);
  };

  // code for closing side nav bar on clicking anywhere
  document.addEventListener("click", function (event) {
    var ignoreElement = document.querySelector("#menuToggle");
    // console.log(ignoreElement)
    if (ignoreElement !== null) {
      var clickElement = ignoreElement.contains(event.target);
      if (!clickElement) {
        document.querySelector("#menuToggle input").checked = false;
      }
    }
  });

  const [ShowServices, setShowServices] = useState("false");

  const HandleServices = (e) => {
    e.preventDefault();
    if (ShowServices === "false") {
      setShowServices("true");
    } else {
      setShowServices("false");
    }
  };

  return (
    <>
      <header className="Navbar">
        <div className="logo">
          <Link to="/">
            {" "}
            <img src={Logo} alt="error loading in image" />
          </Link>
        </div>
        <nav className="NavLink">
          <li
            className={`links ${props.curPage === "home" ? "active" : ""}`}
            //   onClick={() => setCurPage("home")}
          >
            <Link to="/">Home</Link>
          </li>

          <li
            className={`links ${props.curPage === "about" ? "active" : ""}`}
            onClick={() => {
              setCurPage("about");
            }}
          >
            <Link to="/about"> About Us</Link>
          </li>
          

          <li className="links">
            <div class="dropdown">
              <button class="dropbtn">Services <img className="down-arrow" src={downArrow} alt="error loading image" /> <img className="up-arrow" src={upArrow} alt="" /></button>
              <div class="dropdown-content">
                <a href="#">
                  <Link to="/dataservice">Data Service</Link>
                </a>
                <a href="#">
                  <Link to="/webservice">Web Service</Link>
                </a>
              </div>
            </div>
          </li>


          <li
            className={`links ${props.curPage === "projects" ? "active" : ""}`}
            onClick={() => {
              setCurPage("projects");
            }}
          >
            <Link to="/projects"> Our Work </Link>
          </li>

          <li
            className={`links ${props.curPage === "careers" ? "active" : ""}`}
            onClick={() => {
              setCurPage("careers");
            }}
          >
            <Link to="/career">Careers</Link>
          </li>

          
        
          <li
            className={`links ${props.curPage === "contact" ? "active" : ""}`}
            onClick={() => {
              setCurPage("contact");
            }}
          >
            <Link to="/contact">
              {" "}
              <button>Contact</button>{" "}
            </Link>
          </li>
        </nav>
        {/* <nav className="contact">
      <li className="links" onClick={ShowContact}>
        Contact us
      </li>
    </nav> */}
      </header>

      {/* ============ divider =================== */}

      {/*  Mobile Navbar from here ... */}

      {/* ============ divider =================== */}

      <header className="mobileView">
        <nav>
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <a href="#">
                <li
                  onClick={() => {
                    document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img src={HomeImg} className="NavImages" alt="Home Image" />
                  <p>
                    <Link to="/">Home</Link>
                  </p>
                </li>
              </a>
              <Link to="#" spy={true} smooth={true} duration={500} offset={-70}>
                <li
                  className={props.curPage == "about" ? "active" : ""}
                  onClick={() => {
                    setCurPage("about");
                    document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img src={aboutImg} className="NavImages" alt="Home Image" />
                  <p>
                    {" "}
                    <Link to="/about">About Us</Link>
                  </p>
                </li>
              </Link>

              <a href="#">
                <li
                  className={props.curPage == "service" ? "active" : ""}
                  onClick={() => {
                    setCurPage("service");
                    // document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img src={ServiceImg} className="NavImages" alt="" />
                  <p onClick={HandleServices}>Services</p>
                </li>
              </a>

              {ShowServices === "true" && (
                <>
                  <a href="#">
                    <li
                      className="Sub-Services"
                      onClick={() => {
                        setCurPage("service");
                        document.querySelector(
                          "#menuToggle input"
                        ).checked = false;
                      }}
                    >
                      <img
                        src={webIcon}
                        className="NavImages"
                        alt="Error loading image"
                      />
                      <p className="Mobile-Web">
                        <Link to="/webservice">Web Services</Link>
                      </p>
                    </li>
                  </a>
                  <a href="#">
                    <li
                      className="Sub-Services"
                      onClick={() => {
                        setCurPage("service");
                        document.querySelector(
                          "#menuToggle input"
                        ).checked = false;
                      }}
                    >
                      <img src={dataBase} className="NavImages" alt="" />
                      <p>
                        <Link to="/dataservice">Data Services</Link>
                      </p>
                    </li>
                  </a>
                </>
              )}

              {ShowServices === "false" && <></>}

              <a href="#">
                <li
                  className={props.curPage == "projects" ? "active" : ""}
                  onClick={() => {
                    setCurPage("projects");
                    document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img
                    src={OurWorkImg}
                    className="NavImages"
                    alt="our work image"
                  />
                  <p>
                    {" "}
                    <Link to="/projects">Our Work</Link>{" "}
                  </p>
                </li>
              </a>

             

              <a href="#">
                <li
                  className={curPage == "projects" ? "active" : ""}
                  onClick={() => {
                    setCurPage("projects");
                    document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img
                    src={CareerImage}
                    className="NavImages"
                    alt="our work image"
                  />
                  <p>
                    {" "}
                    <Link to="/career">Career</Link>{" "}
                  </p>
                </li>
              </a>


              <a href="#">
                <li
                  className={props.curPage == "contact" ? "active" : ""}
                  onClick={() => {
                    setCurPage("contact");
                    document.querySelector("#menuToggle input").checked = false;
                  }}
                >
                  <img
                    src={contactImg}
                    className="NavImages"
                    alt="error loading image"
                  />
                  <p>
                    {" "}
                    <Link to="/contact">contact Us</Link>
                  </p>
                </li>
              </a>

           
              <li
                className="Social-Media-Links"
                onClick={() => {
                  setCurPage("contact");
                  document.querySelector("#menuToggle input").checked = false;
                }}
              >
                <a
                  href="https://instagram.com/capnxtglobal?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <img src={instagram} alt="instagram image" />
                </a>
                <a
                  href="https://www.facebook.com/Capnxtglobal/"
                  target="_blank"
                >
                  <img src={faceBook} alt="facebook Image" />
                </a>
                <a
                  href="https://in.linkedin.com/company/capnxt-global"
                  target="_blank"
                >
                  <img src={linkedIn} alt="Linked Image" />
                </a>
              </li>
            </ul>
          </div>
          <div class="logo-title">
            <div className="CapNxt-logo">
              <Link to="/">
                <img src={Logo} alt="not found" />
              </Link>
            </div>

            {/* <div className="Call-Logo">
           <a href="tel:+91 7981516766"> <img src={callLogo} className="call-logo" alt="call logo" /></a>
            </div> */}
            
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
