import React , {useState, useEffect} from "react";

function ScrollHandler() {
  const [initialColor, setInitialColor] = useState("#182232");
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, clientHeight } = document.documentElement;
      const maxScrollTop = scrollHeight - clientHeight;
      const currentScrollTop = maxScrollTop - window.pageYOffset;
      const scrollPosition = window.pageYOffset;

      if (currentScrollTop <= 280) {
        setInitialColor("#ffffff");
      } else if (scrollPosition >= 500) {
        setScroll(true);
        setInitialColor("#182232");
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {scroll && (
        <div className="got-to-top" onClick={ScrollToTop}>
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.5 0C1.567 0 0 1.567 0 3.5V31.5C0 33.433 1.567 35 3.5 35H31.5C33.433 35 35 33.433 35 31.5V3.5C35 1.567 33.433 0 31.5 0H3.5ZM19.25 26.95V14.7249L21.8626 17.3374C22.546 18.0209 23.654 18.0209 24.3374 17.3374C25.0209 16.654 25.0209 15.546 24.3374 14.8626L18.7374 9.26256C18.3957 8.92085 17.9479 8.75 17.5 8.75C17.2627 8.75 17.0365 8.79722 16.8301 8.88279C16.6237 8.96819 16.4303 9.09478 16.2626 9.26256L10.6626 14.8626C9.97915 15.546 9.97915 16.654 10.6626 17.3374C11.346 18.0209 12.454 18.0209 13.1374 17.3374L15.75 14.7249V26.95C15.75 27.9165 16.5335 28.7 17.5 28.7C18.4665 28.7 19.25 27.9165 19.25 26.95Z"
              fill={initialColor}
            />
          </svg>
        </div>
      )}
    </>
  );
}

export default ScrollHandler;
