import React from "react";
import "../StyleComponents/FooterStyle.css";
import capnxtLogWhite from "../Assets/Images/capnxtLogWhite.svg";
import instagram from "../Assets/Images/instagram.svg";
import { Link, Route, Routes } from "react-router-dom";
import facebook from "../Assets/Images/faceBook.svg";
import linkedIn from "../Assets/Images/linkedIn.svg";
import AddressLogo from "../Assets/Images/addressLogo.svg";
import PhoneLogo from "../Assets/Images/mobileLogo.svg";
import EmailLogo from "../Assets/Images/EmailLogo.svg";
import capnxtLogo from "../Assets/Images/Capnxt-Logo-Final--White.png";

function Footer() {
  return (
    <>
      {/* Previously designed fotter vamsi design ..... */}

      <section className="Footer">
        <div className="socailMedia">
          <Link to="/">
            {" "}
            <img
              className="CapNxtLogo"
              src={capnxtLogWhite}
              alt="error loading in image"
            />
          </Link>
          <p>
            Capnxt provides web development and data science services to help
            businesses achieve their goals with custom solutions, experienced
            developers, and advanced analysis techniques.
          </p>
          <div className="socailMediaLinks">
            <a
              target="_blank"
              href="https://in.linkedin.com/company/capnxt-global"
            >
              <img src={linkedIn} alt="error loading image" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/capnxtglobal?igshid=YmMyMTA2M2Y="
            >
              <img src={facebook} alt="error loading image" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/capnxtglobal?igshid=YmMyMTA2M2Y="
            >
              <img src={instagram} alt="error loading image" />
            </a>
          </div>
        </div>
        <section className="Links">
          <div className="Navlinks">
            <h1>Links</h1>
            <p>
              <Link to="/about">About Us</Link>
            </p>
            <p>
              <Link to="/projects">Projects</Link>
            </p>
            <p>
              <Link to="/dataservice">data service</Link>
            </p>
            <p>
              <Link to="/webservice">web service</Link>
            </p>
            <p>
              <Link to="/contact">contact us</Link>
            </p>
          </div>
          <div className="ContactDetails">
            <section className="contactLinks">
              <h1>Contact Info</h1>
              <div>
                <img src={AddressLogo} alt="error loading" />
                <span
                  className="link"
                  onClick={() => {
                    window.open(
                      "https://goo.gl/maps/j63bu55Ekgj2eNLVA",
                      "_blank"
                    );
                  }}
                >
                  95, Lumbini Avenue, Gachibowli, Hyderabad, <br />
                  Telangana 500032
                </span>
              </div>

              <div>
                <img src={PhoneLogo} alt="error loading" />
                <span
                  className="link"
                  onClick={() => {
                    window.open("tel:+917981516766");
                  }}
                >
                  +91 7981516766
                </span>
              </div>

              <div>
                <img src={EmailLogo} alt="error loading" />
                <span
                  className="link"
                  onClick={() => {
                    window.open("mailto:info@capnxt.com");
                  }}
                >
                  info@capnxt.com
                </span>
              </div>
            </section>
          </div>
        </section>

        <div className="socailMediaLinksMobile">
          <a
            target="_blank"
            href="https://in.linkedin.com/company/capnxt-global"
          >
            <img src={linkedIn} alt="error loading image" />
          </a>
          <a target="_blank" href="https://www.facebook.com/Capnxtglobal/">
            <img src={facebook} alt="error loading image" />
          </a>
          <a
            target="_blank"
            href="https://instagram.com/capnxtglobal?igshid=YmMyMTA2M2Y="
          >
            <img src={instagram} alt="error loading image" />
          </a>
        </div>
      </section>

      {/* New Footer design basic One .... */}

      {/* <section className="mobile-Footer">
        <div className="Logo">
          <img src={capnxtLogo} alt="error loading..." />
        </div>

       <section className="social-copyright">
       <div className="Footer-social-link">
        <img src={instagram} alt="error loading .." />
          <img src={facebook} alt="error loading .." />
          <img src={linkedIn} alt="error loading .." />
        </div>

        <div className="copy-right">
          <p>©2023 CapNxt Global pvt ltd</p>
        </div>

       </section>
      </section> */}
      <div className="copyright">
        <p>
          &copy; {new Date().getFullYear()} | Powered by <a href="https://vaikharidigital.com" target="_blank" style={{color : '#fff',textDecoration : 'underline'}}>Vaikhari Digital</a>
        </p>
      </div>
    </>
  );
}

export default Footer;
