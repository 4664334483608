import React, { useEffect } from "react";
import "../StyleComponents/DataService.css";
import dataScienceImg from "../Assets/Images/DataScienceImage.svg";
import ourApproachImg from "../Assets/Images/ourApproach.svg";
import solutionImageOne from "../Assets/Images/solutionImg1.svg";
import solutionImageTwo from "../Assets/Images/solutionImg2.svg";
import Navbar from "./Navbar";
import ScrollHandler from "./ScrollHandler";
import Footer from "./Footer";
import mobileDataImage from "../Assets/Images/mobile-dataImage.svg";
import MobileourApproachOne from "../Assets/Images/01ourApproach.svg";
import MobileourApproachTwo from "../Assets/Images/02ourApproach.svg";
import MobileourApproachThree from "../Assets/Images/03ourApproach.svg";
import MobileourApproachFour from "../Assets/Images/04ourApproach.svg";
import MobileourApproachFive from "../Assets/Images/05ourApproach.svg";
import MobileourApproachSix from "../Assets/Images/06ourApproach.svg";

function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section id="Service">
        <header className="ServiceHeader">
          {/* <h1>Data</h1> */}
          <h1>Data</h1>
        </header>
        <div className="mobile-heading">
          <h1>Data Analytics</h1>
        </div>
        <section className="DataContent">
          <div className="mobile-contentImg">
            <img src={mobileDataImage} alt="" />
          </div>
          <header>
            <h1>Data Analytics</h1>
            <p>
              Data analytics is the process of examining and interpreting data
              using statistical and computation methods to extract insights and
              knowledge from it. It involves the collection processing,
              cleaning, and analysis of large sets of data to identify patterns,
              relationships, and trends.
            </p>
          </header>
          <div className="contentImg">
            <img src={dataScienceImg} alt="" />
          </div>
        </section>
        {/*  Mobile Approeuch here...... */}
        <section className="Mobile-Approch">
          <header>
            <h1>Our Approach</h1>
          </header>
          <div className="Mobile-Approach-cards">
            <section className="Left-Content">
              <div className="content-Container">
                <div className="heading">
                  <h1>01</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  We Identify the Client needs, develop innovative solutions,
                  and create value for customers, investors, and stakeholders.
                </p>
              </div>
              <div className="img">
                <img src={MobileourApproachOne} alt="Error loading" />
              </div>
            </section>

            <section className="Right-Content">
              <div className="img">
                <img src={MobileourApproachTwo} alt="Error loading" />
              </div>
              <div className="content-Container">
                <div className="heading">
                  <h1>02</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  Gathering relevant data from various sources, such as
                  databases, websites, social media platforms, and sensors.
                </p>
              </div>
            </section>

            <section className="Left-Content">
              <div className="content-Container">
                <div className="heading">
                  <h1>03</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  cleaning and preparing the data to ensure its quality,
                  consistency, and accuracy
                </p>
              </div>
              <div className="img">
                <img src={MobileourApproachTwo} alt="Error loading" />
              </div>
            </section>

            <section className="Right-Content">
              <div className="img">
                <img src={MobileourApproachFour} alt="Error loading" />
              </div>
              <div className="content-Container">
                <div className="heading">
                  <h1>04</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  Using statistical and computational methods to analyze the
                  data, identify patterns and relationships.
                </p>
              </div>
            </section>

            <section className="Left-Content">
              <div className="content-Container">
                <div className="heading">
                  <h1>05</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  We create a visual representation of data relationships and
                  structures, consistency, and decision-making capabilities.
                </p>
              </div>
              <div className="img">
                <img src={MobileourApproachThree} alt="Error loading" />
              </div>
            </section>

            <section className="Right-Content">
              <div className="img">
                <img src={MobileourApproachSix} alt="Error loading" />
              </div>
              <div className="content-Container">
                <div className="heading">
                  <h1>06</h1>
                  <h1>Problem Understanding</h1>
                </div>
                <p className="content">
                  We analyze client needs and provide personalized
                  recommendations based on the data and interpret them in order
                  to make informed decisions.
                </p>
              </div>
            </section>
          </div>
        </section>

        <section className="Approch">
          <header>
            <h1>Our Approach</h1>
          </header>
          <div className="sectionHolder">
            <section className="boxContainerTop">
              <div className="boxCollector">
                <div className="box">
                  <h1>Data Collection</h1>
                  <p>
                    Gathering relevant data from various sources, such as
                    databases, websites, social media platforms, and sensors.
                  </p>
                </div>
                <div className="box middleBox">
                  <h1>Data Analysis</h1>
                  <p>
                    Using statistical and computational methods to analyze the
                    data, identify patterns and relationships.
                  </p>
                </div>
                <div className="box">
                  <h1>Data Interpretation</h1>
                  <p>
                    We analyze client needs and provide personalized
                    recommendations based on the data and interpret them in
                    order to make informed decisions.
                  </p>
                </div>
              </div>
            </section>
            <section className="ApproachImage">
              <img src={ourApproachImg} alt="error loading image" />
            </section>
            <section className="boxContainerBottom">
              <div className="boxCollectorBottom">
                <div className="box">
                  <h1>Problem understanding</h1>
                  <p>
                    We Identify the Client needs, develop innovative solutions,
                    and create value for customers, investors, and stakeholders.
                  </p>
                </div>
                <div className="box middleBox">
                  <h1>Data Cleaning</h1>
                  <p>
                    cleaning and preparing the data to ensure its quality,
                    consistency, and accuracy
                  </p>
                </div>
                <div className="box">
                  <h1>Data Modelling</h1>
                  <p>
                    We create a visual representation of data relationships and
                    structures, consistency, and decision-making capabilities.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section className="Solutions">
          <header>
            <h1>Solutions</h1>
          </header>
          <div className="solutionImgContainer">
            <div className="solutionBox">
              <img src={solutionImageOne} alt="error loading in image.." />
              <div className="Solutioncontent">
                <h1>Descriptive Analytics</h1>
                <p>
                  Summarizes and describes data using statistical techniques and
                  visualization tools, providing a foundation for more advanced
                  analysis techniques and data-driven decision making.
                </p>
              </div>
            </div>
            <div className="solutionBox">
              <img
                src={solutionImageTwo}
                className="solutionImageTwo"
                alt="error loading in image.."
              />
              <div className="Solutioncontent">
                <h1>Diagnostic Analytics</h1>
                <p>
                  Uses statistical and machine learning algorithms to analyze
                  historical data and make predictions about events or outcomes,
                  enabling organizations to make data-driven decisions
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Footer />
      <ScrollHandler />
    </>
  );
}

export default Service;
