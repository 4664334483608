// import { Button, Modal, Backdrop, Fade } from '@material-ui/core';
// import { AddCircle } from '@material-ui/icons';
import React, { useState } from "react";
import "../StyleComponents/WebService.css";
import WebDevelopmentImage from "../Assets/Images/WebDevlopmentImg.svg";
import WebCardOne from "../Assets/Images/WebCardImage1.svg";
import WebCardTwo from "../Assets/Images/WebCardImage2.svg";
import WebCardThree from "../Assets/Images/WebCardImage3.svg";
import WebCardFour from "../Assets/Images/WebCardImage4.svg";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import ScrollHandler from "./ScrollHandler";
import Footer from "./Footer";
import { useEffect } from 'react';

function WebService() {
  const navigate = useNavigate();

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  return (
    <>
      <Navbar />
      
      <section className="WebService">
        <div className="Web-service-mobile-heading">
            <h1>Web Development</h1>
        </div>
        <header>
        <div className="mobile-WebContentImage">
            <img src={WebDevelopmentImage} alt="Error loading in image" />
          </div>
          <div className="WebContent">
            <h1>Web Development</h1>
            <p>
              We are a team of ingenious designers, who believe that, “Good
              Design is Good Business” CapNxt Aims at offering Clients/Users a
              personalized experience for their websites that are innovative in
              every way you can imagine.
            </p>
          </div>
          <div className="WebContentImage">
            <img src={WebDevelopmentImage} alt="Error loading in image" />
          </div>
        </header>
        <div className="WhatWeDo">
          <div className="whatWeDoImage">
            <h1>
              What <br /> we do?{" "}
            </h1>
          </div>
          <div className="WhatWeDoCards">
            <div className="card CardOne">
              <div className="CardContent">
                <h1>User Experience</h1>
                <p>
                We offer unique customization options (for e.g.: Unique templates, custom designs or the ability to create custom features to meet the client needs) that others do not.
                </p>
              </div>
              <div className="CardImage">
                <img src={WebCardOne} alt="error loading image." />
              </div>
            </div>
            <div className="card CardTwo">
              <div className="CardContent">
                <h1>Speed & Performance</h1>
                <p>
                Our main focus is on creating websites that load quickly and perform well, so that it keeps visitors engaged and improves search engine rankings.
                </p>
              </div>
              <div className="CardImage">
                <img src={WebCardTwo} alt="error loading image." />
              </div>
            </div>
            <div className="card CardThree">
              <div className="CardContent">
                <h1>Advance Technologies</h1>
                <p>
                Our company employs advanced technologies to meet client requirements, delivering effective and innovative solutions that exceed expectations.
                </p>
              </div>
              <div className="CardImage">
                <img src={WebCardThree} alt="error loading image." />
              </div>
            </div>
            <div className="card cardFour">
              <div className="CardContent">
                <h1>Intelligent Assessment</h1>
                <p>
                CapNxt offers intelligent pricing by determining the true cost of running our business as well as our competitor rates. We always make sure that the assessed value fits the client needs.
                </p>
              </div>
              <div className="CardImage">
                <img src={WebCardFour} alt="error loading image." />
              </div>
            </div>
          </div>
        </div>
        <div className="GetQuote">
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            Get a Quote
          </button>
        </div>
      </section>
      <Footer />
      <ScrollHandler />

      {/* <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircle />}
        onClick={() => setOpen(true)}
      >
        Add Item
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            <form>
              <h2>Add Item</h2>
              <label htmlFor="item-name">Name:</label>
              <input type="text" id="item-name" />
              <br />
              <label htmlFor="item-description">Description:</label>
              <textarea id="item-description" rows="3"></textarea>
              <br />
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </div> */}
    </>
  );
}

export default WebService;
