import React, { useState, useEffect } from "react";
import "../StyleComponents/HomeStyle.css";
import videoBackground from "../Assets/Videos/Logo Rotate-1.mp4";
// import videoBackground from "../Assets/Videos/Logo-Rotate-2-.mp4";
// import videoBackground from "../Assets/Videos/Logo-Rotate-3.mp4";
// import videoBackground from "../Assets/Videos/Logo-Rotate-4.mp4";
import AboutImg from "../Assets/Images/aboutImg.svg";
import serviceImgOne from "../Assets/Images/servicesImg.svg";
import serviceImgTwo from "../Assets/Images/serviceImg2.svg";
import TechImg from "../Assets/Images/techImg.svg";
import BlogImg from "../Assets/Images/blogimg.svg";
import BlogImgtwo from "../Assets/Images/blogimg2.svg";
import BlogImgthree from "../Assets/Images/blogimg3.svg";
import CalenderImg from "../Assets/Images/calender.svg";
import clientOne from "../Assets/Images/clientOne.svg";
import clientTwo from "../Assets/Images/clientTwo.svg";
import clientThree from "../Assets/Images/ClientThree.svg";
import clientFour from "../Assets/Images/clientFour.svg";
import vaikhari from "../Assets/Images/vaikhari.svg";
import learnMore from "../Assets/Images/LearnMoreLogo.svg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollHandler from "./ScrollHandler";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  // we work to give results but  not apologies
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Navbar curPage="home" />
      <section className="HomePage">
        <div className="HomeContainer">
          <div className="Quotation">
            <span className="LeftLine"></span>
            <div>
              <h1 className="Connecting">CONNECTING</h1>
              <h1 className="TextStoke RESOURCES">RESOURCES</h1>
              <h1 className="TextStoke IDEAs">IDEAS</h1>
              <h1 className="TextStoke PEOPLE">PEOPLE</h1>
            </div>
          </div>
          <div className="Video">
            {/* <video src={videoBackground}  autoplay loop muted /> */}
            <video autoPlay loop muted playsInline id="video">
              <source src={videoBackground} type="video/mp4" />
            </video>
            {/* </video> */}
          </div>
        </div>
        <div className="HomeABout">
          <h1 className="About-mobile-heading">About Us</h1>
          <div className="aboutImg">
            <img src={AboutImg} alt="about img" />
          </div>
          <div className="aboutContent">
            <h1>About Us</h1>
            <p>
              At CapNxt, we are passionate about innovation and efficiency. We
              stay up-to-date on the latest trends and technologies to ensure
              that our clients are always ahead of the curve. Whether you need a
              simple website or a complex data analytics solution, we have the
              expertise to deliver results that exceed your expectations. Our
              commitment to quality and customer satisfaction sets us apart from
              the competition. Let CapNxt help you reach your full potential and
              achieve your business goals.
            </p>
            <Link to="/about">
              {" "}
              <button>learn More</button>
            </Link>
          </div>
        </div>
        <div className="HomeServices">
          <header>
            <p>Services</p>
            {/* <h1>How Can We Help</h1> */}
          </header>
          <section className="serviceImages">
            <div
              className="box"
              onClick={() => {
                navigate("/webservice");
              }}
            >
              <img src={serviceImgOne} alt="servie Image" />
              <p>Web Development</p>
              <p
                className="link"
                onClick={() => {
                  navigate("/webservice");
                }}
              >
                {" "}
                Learn More <img src={learnMore} alt="" />
              </p>
            </div>

            <div
              className="box"
              onClick={() => {
                navigate("/dataservice");
              }}
            >
              <img src={serviceImgTwo} alt="servie Image" />
              <p>Data Science</p>
              <p
                className="link"
                onClick={() => {
                  navigate("/dataservice");
                }}
              >
                {" "}
                Learn More <img src={learnMore} alt="" />
              </p>
            </div>

            <div className="box"  onClick={() => {
                  window.alert("Cloud Computing coming soon 😊")
                }}>
              <img src={serviceImgOne} alt="servie Image" />
              <p>Cloud Computing</p>
              <p
                className="link"
                onClick={() => {
                  window.alert("Cloud Computing coming soon 😊")
                }}
              >
                {" "}
                coming soon ..
              </p>
            </div>
          </section>
        </div>
        <div className="HomeTech">
          <header>
            <p>Our Technologies</p>
            {/* <h1>How Can We Help</h1> */}
          </header>
          <div className="techImg">
            <div className="tech-content">
              At CapNxt Global, we specialize in web development and data
              analytics solutions that help businesses achieve their goals. Are
              you struggling to make sense of your data and use it to drive
              business decisions? At CapNxt Global, we help businesses overcome
              these challenges and unlock the full potential of their
              technology. Our team of web development and data analytics experts
              brings a unique blend of technical expertise and business acumen
              to every project we undertake. We're dedicated to delivering
              high-quality solutions that meet our clients' unique needs and
              help them achieve their goals. Contact us today to schedule a
              consultation with one of our experts. We look forward to hearing
              from you!
            </div>
            <img src={TechImg} alt="tech Images" />
          </div>
        </div>

        <div className="HomeBlog">
          <header>
            <p>Blog</p>
            {/* <h1>Stories and Reviews</h1> */}
          </header>

          <div className="blogImagecontainer">
            <Link to="blogs/reactjs">
              <div className="blogBox">
                <img className="blogImg" src={BlogImg} alt="blog image error" />
                <div className="time">
                  <img src={CalenderImg} alt="calender image error" />
                  <p>2 Jan 2023</p>
                </div>
                <div className="content">
                  <h1>ReactJS</h1>
                  <p>
                    Why is ReactJS so popular among the developers. What does it
                    Offer?
                  </p>
                  <p className="link">
                    <Link to="/blog#Node"> Learn More </Link>
                  </p>
                </div>
              </div>
            </Link>

            <Link to="blogs/cloudcomputing">
              <div className="blogBox">
                <img
                  className="blogImg"
                  src={BlogImgtwo}
                  alt="blog image error"
                />
                <div className="time">
                  <img src={CalenderImg} alt="calender image error" />
                  <p>6 Feb 2023</p>
                </div>
                <div className="content">
                  <h1>Cloud Computing</h1>
                  <p>The two "W's" of Cloud Computing and its Trends</p>
                  <p className="link">
                    <Link to="/blog#Node"> Learn More </Link>
                  </p>
                </div>
              </div>
            </Link>

            <Link to="blogs/nodejs">
              <div className="blogBox">
                <img
                  className="blogImg"
                  src={BlogImgthree}
                  alt="blog image error"
                />
                <div className="time">
                  <img src={CalenderImg} alt="calender image error" />
                  <p>3 March 2023</p>
                </div>
                <div className="content">
                  <h1>NodeJS</h1>
                  <p>
                    Why NodeJS is still trending among the developers in the
                    Front-end
                  </p>
                  <p className="link">
                    <Link to="/blog"> Learn More </Link>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="HomeClients">
          <header>
            <h1>Our Clients</h1>
          </header>
          <div className="imageContainer">
            <div className="imageBox">
              <a href="https://energon.in" target="_blank">
                {" "}
                <img src={clientOne} alt="clientOne" />
              </a>
            </div>
            <div className="imageBox">
              <a href="https://vaikharidigital.com/" target="_blank">
                <img src={vaikhari} alt="clientOne" />
              </a>
            </div>
            <div className="imageBox" style={{ marginTop: "-1vw" }}>
              <img src={clientThree} alt="clientOne" />
            </div>
            <div className="imageBox" style={{ marginTop: "-1vw" }}>
              <a href="https://prakrutischool.in" target="_blank">
                {" "}
                <img src={clientFour} alt="clientOne" />
              </a>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
      <ScrollHandler />
    </>
  );
}

export default Home;
