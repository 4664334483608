import React, { useState, useEffect } from "react";
import "../StyleComponents/ProjectStyle.css";
import ProjectourWork from "../Assets/Images/ProjectOurWork.svg";
import ProductImg from "../Assets/Images/ProductImage.svg";
import TargetImg from "../Assets/Images/TarketImage.svg";
import UseCaseImg from "../Assets/Images/UseCaseImage.svg";
import vaikhari from "../Assets/Images/vaikhari.svg";
import PrakruthiSchool from "../Assets/Images/clientFour.svg";
import EnergonBG from "../Assets/Images/EnergonBG.svg";
import EnnessaarBG from "../Assets/Images/EnnessaarBG.svg";
import PrakrutiAvenuesBG from "../Assets/Images/PrakrutiAvenuesBG.svg";
import PrakrutiSchoolBG from "../Assets/Images/PrakrutiSchoolBG.svg";
import VaikhariBG from "../Assets/Images/VaikhariBG.svg";
import Energon from "../Assets/Images/clientOne.svg";
import Ennessaar from "../Assets/Images/Ennessaar.svg";
import PrakrutiAvenues from "../Assets/Images/PrakrutiAvenues";
import ArkitonImage from "../Assets/Images/ClientThree.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import reactImage from "../Assets/Images/reactImg.svg";
import angularImage from "../Assets/Images/angular.svg";
import htmlImage from "../Assets/Images/html.svg";
import cssImage from "../Assets/Images/css.svg";
import mongodbImage from "../Assets/Images/mongodb.svg";
import nodejsImage from "../Assets/Images/nodejs.svg";
import wordPressImage from "../Assets/Images/wordpress.svg";
import animejsImage from "../Assets/Images/animejs.svg";
import crossImage from "../Assets/Images/crossImage.svg";
import websiteLink from "../Assets/Images/websiteLink.svg";
import Navbar from "./Navbar";
import ScrollHandler from "./ScrollHandler";
import Footer from "./Footer";

function Projects() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "white",
    boxShadow: 0,
    borderRadius: "1vw",
    border: "unset !important",
  };

  // handling modal state

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  //json stored details here ......
  let ProjectDetails = [
    {
      ProjectName: "Energon",
      BackgroundImg: EnergonBG,
      ProjectDesp:
        "Energon LPG bottling plant's dashboard visualizes critical data and metrics to monitor performance and drive data-driven decisions.",
      ProductDesp:
        "Energon dashbord is visual representation of critical data and metrics of plant day to day data by which organization members can monitor plant performance, do inventory management  and make data-driven decisions.",
      TargetMarket:
        "LPG bottling companies are businesses that produce and distribute LPG cylinders or tanks to other businesses for commercial or industrial use. These businesses may include restaurants, hotels, manufacturing facilities, and other businesses that use LPG as a fuel source.",
      UseCase:
        "Monitor KPIs, optimize production process, real-time sales visibility, track financial metrics - revenue, costs, and profitability - with our bottling plant dashboard.",
      ProjectImage: Energon,
      ProjectLink: "https://energon.in",
      ProductImage: ProductImg,
      TargetImage: TargetImg,
      useCaseImage: UseCaseImg,
      techImages: [
        {
          className: "tech-One",
          path: reactImage,
        },
        {
          className: "tech-Two",
          path: nodejsImage,
        },
        {
          className: "tech-Three",
          path: mongodbImage,
        },
      ],
    },

    {
      ProjectName: "Prakruti School",
      BackgroundImg: PrakrutiSchoolBG,
      ProjectDesp:
        "A website for Prakruti School can provide information about the school, its curriculum, faculty, facilities, and admission procedures.",
      ProductDesp:
        "Prakruti school's website can showcase its vision, curriculum, faculty, and facilities. The website can also provide information about admissions, events, and alumni.",
      TargetMarket:
        "A website for a school is important as it serves as a digital platform to showcase the school's vision, curriculum, faculty, and facilities. It provides easy access to information for parents, students, and potential employees, and helps to enhance the school's online presence.",
      UseCase:
        "A school website that provides admissions info, academic programs, faculty, facilities, events, news, communication tools, and showcase achievements, culture, and values to the community.",
      ProjectImage: PrakruthiSchool,
      ProjectLink: "https://prakrutischool.in/",
      ProductImage: ProductImg,

      TargetImage: { TargetImg },
      TargetDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      useCaseImage: UseCaseImg,
      useCaseDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      techImages: [
        {
          className: "tech-One",
          path: htmlImage,
        },
        {
          className: "tech-Two",
          path: nodejsImage,
        },
        {
          className: "tech-Three",
          path: cssImage,
        },
      ],
    },
    {
      ProjectName: "Vaikhari Digital",
      BackgroundImg: VaikhariBG,
      ProjectDesp:
        "A website for Vaikhari Digital can showcase their services, portfolio, team, and allow potential clients to request quotes.",
      ProductDesp:
        " Vaikhari Digital website showcases digital marketing & design services,consultation & training. Modern design & SEO enhance user experience, attract clients.",
      TargetMarket:
        "A digital design company provides creative services to businesses and individuals to help them build a strong online presence. These services may include website design, graphic design, branding, social media marketing, and search engine optimization. ",
      UseCase:
        "A website for ”Vaikhari Digital” that showcases services, team, successful projects, enable quote requests, consultation bookings, SEO optimization, social media integration to attract potential clients.",
      ProjectImage: vaikhari,
      ProjectLink: "https://vaikharidigital.com/",
      ProductImage: ProductImg,

      TargetImage: TargetImg,
      TargetDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      useCaseImage: UseCaseImg,
      useCaseDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      techImages: [
        {
          className: "tech-One",
          path: reactImage,
        },
        {
          className: "tech-Two",
          path: animejsImage,
        },
        {
          className: "tech-Three",
          path: cssImage,
        },
      ],
    },
    {
      ProjectName: "Ennessaar",
      BackgroundImg: EnnessaarBG,

      ProjectDesp:
        " A website for the company Ennessaar, that can be used to showcase current & completed projects, provide information about services and more",
      ProductDesp:
        "Prakruti school's website can showcase its vision, curriculum, faculty, and facilities. The website can also provide information about admissions, events, and alumni.",
      TargetMarket:
        "A website for a school is important as it serves as a digital platform to showcase the school's vision, curriculum, faculty, and facilities. It provides easy access to information for parents, students, and potential employees, and helps to enhance the school's online presence.",
      UseCase:
        "A website for a school can be used to provide information on admissions, academic programs, faculty, facilities, events, and news. It can also facilitate communication between teachers, students, and parents, and showcase the school's achievements, culture, and values to the wider community.",
      ProjectImage: Ennessaar,
      ProjectLink: "https://ennessaar.com/",
      ProductImage: ProductImg,

      TargetImage: TargetImg,
      TargetDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      useCaseImage: UseCaseImg,
      useCaseDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      techImages: [
        {
          className: "tech-Two",
          path: wordPressImage,
        },
      ],
    },
    {
      ProjectName: "Prakruti Avenues",
      BackgroundImg: PrakrutiAvenuesBG,
      ProjectDesp:
        "A website for Prakruti Avenues Pvt. Ltd can showcase their portfolio of successful projects, services offered, and ongoing projects.",
      ProductDesp:
        "Prakruti Avenues Pvt. Ltd.'s website can showcase their 35 years of experience and specialization in township projects. The website can also feature their portfolio of successful projects,",
      TargetMarket:
        "A real estate company specializes in buying, selling, renting, or managing properties on behalf of clients. They offer services such as property valuation, marketing, negotiations, and legal advice.",
      UseCase:
        "A website for Prakruti Avenues Pvt. Ltd can showcase their portfolio of successful projects, customer testimonials, and services offered. It can also provide information about ongoing projects and allow potential.",
      ProjectImage: PrakrutiAvenues,
      ProjectLink: "https://www.prakrutiavenues.com/",
      ProductImage: ProductImg,

      TargetImage: TargetImg,
      TargetDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      useCaseImage: UseCaseImg,
      useCaseDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      techImages: [
        {
          className: "tech-One",
          path: angularImage,
        },
        {
          className: "tech-Two",
          path: nodejsImage,
        },
        {
          className: "tech-Three",
          path: mongodbImage,
        },
      ],
    },

    {
      ProjectName: "Arkiton",
      BackgroundImg: EnergonBG,
      ProjectDesp:
        "Arkiton's website is to  showcase timeless and beautiful spaces created with creativity and Integrity, technology, and expertise.",
      ProductDesp:
        "Arkiton combines imagination, experience, and skill to create timeless works of art in both exterior and interior design. We prioritize finesse, technology, professionalism, and versatility.",

      TargetMarket:
        "Arkiton is a design-led practice providing contemporary solutions and financial planning for private homeowners, developers, retailers, and leisure operators",
      UseCase:
        "Arkiton's website showcases timeless and beautiful spaces created with creativity, technology, and expertise. We offer architecture, planning, engineering, and product design services for clients.",
      ProjectImage: ArkitonImage,
      ProjectLink: "https://arkitonassociates.com/",
      ProductImage: ProductImg,

      TargetImage: TargetImg,
      TargetDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",
      useCaseImage: UseCaseImg,
      useCaseDesp:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis mattis erat eu molestie. Integer placerat, dui vel ullamcorper pretium, dui ipsum t",

      techImages: [
        {
          className: "tech-One",
          path: htmlImage,
        },
        {
          className: "tech-Two",
          path: cssImage,
        },
        {
          className: "tech-Three",
          path: nodejsImage,
        },
      ],
    },
  ];

  //changing the projects class styling
  const [projectsClass, setprojectsClass] = useState("Projects");

  const [curProject, setProject] = useState(null);
  const handleClose = () => {
    setProject(null);
    setprojectsClass("Projects");
  };

  return (
    <>
      <Navbar curPage="projects" />
      <section className={projectsClass}>
        <header>
          <img src={ProjectourWork} alt="error loading in image." />
          <h1>Our Work</h1>
        </header>

        <section className="ProjectsCards">
          {/* <div className="Card">
            <img src={vaikhari} alt="error loading" />
            <h1>Vaikhari</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              posuere gravida semper. Donec maximus elit molestie.
            </p>
            <p className="Link">Learn More</p>
          </div>*/}

          {ProjectDetails.map((details, index) => {
            return (
              <>
                <div
                  className="Card"
                  onClick={() => {
                    setprojectsClass("ProjectsBlur");
                    setProject(details.ProjectName);
                  }}
                >
                  <img src={details.ProjectImage} alt="error loading" />
                  <h1>{details.ProjectName}</h1>
                  <p>{details.ProjectDesp}</p>
                  <p className="Link">Learn More</p>
                </div>
              </>
            );
          })}
        </section>
      </section>

      <div className="ProjectDeskStop">
        {/* <Button onClick={handleOpen}>Open modal</Button> */}

        {ProjectDetails.map((data, index) => {
          return screenWidth > 768 ? (
            <>
              <Modal
                open={curProject === data.ProjectName}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <section
                    className="modalSection"
                    style={{
                      backgroundImage: `url(${data.BackgroundImg})`,
                    }}
                  >
                    <div className="ModalContent">
                      <header>
                        <h1>{data.ProjectName}</h1>
                        <img
                          src={crossImage}
                          onClick={handleClose}
                          alt="error loading"
                        />
                      </header>
                      <div className="Product-box">
                        <img src={ProductImg} alt="error loading" />
                        <h1>Product</h1>
                        <p>{data.ProductDesp}</p>
                      </div>

                      <div className="BottomBox">
                        <section className="box-container">
                          <div className="box">
                            <img src={TargetImg} alt="error loading" />
                            <h1>Target Market</h1>
                            <p>{data.TargetMarket}</p>
                          </div>
                          <div className="box">
                            <img src={TargetImg} alt="error loading" />
                            <h1>Use Case</h1>
                            <p>{data.UseCase}</p>
                          </div>
                        </section>
                        <div className="WebsiteLink">
                          <img src={websiteLink} alt="" />
                          <a
                            href={data.ProjectLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {data.ProjectLink}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="techImage">
                      {data.techImages.map((image, index) => {
                        return (
                          <div className={image.className}>
                            <img
                              key={index}
                              src={image.path}
                              alt={`${index + 1}`}
                            />
                          </div>
                        );
                      })}
                      {/* <div className="tech-One">
                      <img src={reactImage} alt="" />
                    </div>
                    <div className="tech-Two">
                      <img src={nodejsImage} alt="error loading" />
                    </div>
                    <div className="tech-Three">
                      <img src={mongodbImage} alt="error loadin" />
                    </div> */}
                    </div>
                  </section>
                </Box>
              </Modal>
            </>
          ) : (
            <Modal
              open={curProject === data.ProjectName}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="Pop-up">
                  <header>
                    <h1>{data.ProjectName}</h1>
                    <img
                      onClick={handleClose}
                      src={crossImage}
                      alt="error loadin image"
                    />
                  </header>
                  <div className="Product">
                    <header>
                      <img src={ProductImg} alt="" />
                      <p>Product</p>
                    </header>
                    <div className="content">
                      <p>{data.ProductDesp}</p>
                    </div>
                  </div>

                  <div className="Bottom-Content">
                    <div className="Target">
                      <header>
                        <img src={TargetImg} alt="" />
                        <p>Target market</p>
                      </header>
                      <div className="content">
                        <p>{data.ProductDesp}</p>
                      </div>
                    </div>
                    <div className="useCase">
                      <header>
                        <img src={UseCaseImg} alt="" />
                        <p>Use Case</p>
                      </header>
                      <div className="content">
                        <p>{data.ProductDesp}</p>
                      </div>
                    </div>
                  </div>

                  <section className="social-Links">
                    {data.techImages.map((image, index) => {
                      return (
                        <img
                          key={index}
                          src={image.path}
                          alt={`${index + 1}`}
                        />
                      );
                    })}
                  </section>

                  <div className="Website-link">
                    <p>{data.ProjectLink}</p>
                  </div>
                </div>
              </Box>
            </Modal>
          );
        })}
      </div>
      <Footer />
      <ScrollHandler />
    </>
  );
}

export default Projects;
