// import logo from "./logo.svg";
// import "./App.css";
import Home from "./Components/Home";
import { Link, Route, Routes } from "react-router-dom";
import DataService from "./Components/DataService";
import WebService from "./Components/WebService";
import Contact from "./Components/Contact";
import AboutUs from "./Components/AboutUs";
import Projects from "./Components/Projects";
import Navbar from "./Components/Navbar";
import Careers from "./Components/Careers/Careers";
import Footer from "./Components/Footer";
import Demo from "./Components/Demo";
import Blogs from "./Components/Blogs";
import Reactjs from "./Components/blogs/Reactjs";
import Nodejs from "./Components/blogs/Nodejs";
import Computing from "./Components/blogs/Computing";
import Dog404 from "./Assets/Images/Dog-404.jpg";
import PageNotFound from "./Components/PageNotFound";

function App() {
  return (
    <>
      <Routes>
        {/* <Switch> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="navbar" element={<Navbar />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="webService" element={<WebService />} />
        <Route exact path="dataService" element={<DataService />} />
        <Route exact path="about" element={<AboutUs />} />
        <Route exact path="projects" element={<Projects />} />
        <Route exact path="career" element={<Careers />} />
        <Route exact path="footer" element={<Footer />} />
        {/* <Route exact path="blog" element={<Blogs />} /> */}
        <Route exact path="blogs/reactjs" element={<Reactjs />} />
        <Route exact path="blogs/nodejs" element={<Nodejs />} />
        <Route exact path="blogs/cloudcomputing" element={<Computing />} />
        <Route
          path="*"
          element={
            <>
             <PageNotFound/>
            </>
          }
        />
        {/* </Switch> */}
      </Routes>
    </>
  );
}

export default App;
